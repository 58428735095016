import { css } from "lit";
import * as mixins from "./mixins";

export const misc = css`
    ${mixins.click(".click")}
    ${mixins.hover(".hover")}

    .ellipsis {
        ${mixins.ellipsis()};
    }

    .nowrap {
        white-space: nowrap;
    }

    .underlined {
        text-decoration: underline;
    }

    pl-icon[spin] {
        animation: spin 1s infinite;
        transform-origin: center 49%;
    }

    .tags {
        display: flex;
        overflow-x: auto;
        align-items: center;
        -webkit-overflow-scrolling: touch;
    }

    .tags.wrapping {
        flex-wrap: wrap;
        overflow: visible;
        gap: 0.5em 0;
    }

    .tags::after {
        content: "";
        display: block;
        width: 1px;
        flex: none;
    }

    .tag {
        border-radius: 0.5em;
        padding: var(--tag-padding, 0.5em);
        text-align: center;
        line-height: 1em;
        border: solid 1px;
        font-family: var(--tag-font-family, var(--font-family));
    }

    .tags .tag:not(:last-child) {
        margin-right: 0.5em;
    }

    .tag.ghost {
        border: dashed 1px;
        background: transparent;
    }

    .tag.warning {
        color: var(--color-negative);
    }

    .input-wrapper {
        display: flex;
        align-items: center;
        padding: 0 10px;
    }

    .input-wrapper pl-input {
        padding: 0;
        background: transparent;
    }

    .search-wrapper {
        display: flex;
        align-items: center;
        position: sticky;
        top: var(--gutter-size);
        z-index: 5;
    }

    .search-wrapper pl-icon {
        opacity: 0.5;
        margin-left: 5px;
    }

    .search-wrapper pl-input {
        font-size: var(--font-size-small);
        height: auto;
        flex: 1;
        background: transparent;
        padding-left: 5px;
    }

    .box {
        border-radius: 0.5em;
        border-color: var(--box-border-color, var(--color-border-secondary));
        border-style: var(--box-border-style, solid);
        border-width: var(--box-border-width, 1px);
        overflow: hidden;
    }

    .box.highlighted {
        --box-border-color: var(--color-highlight);
        --border-color: var(--color-highlight);
    }

    .box.negative.highlighted {
        --box-border-color: var(--color-negative);
        --border-color: var(--color-negative);
    }

    .background,
    .bg {
        background: var(--color-background-primary);
    }

    .background-dark,
    .bg-dark {
        background: var(--color-background-dark);
    }

    .uppercase {
        font-variant: all-small-caps;
        letter-spacing: 0.1em;
    }

    .font-mono {
        font-family: var(--font-family-mono);
    }

    .border-bottom {
        border-bottom: solid 1px var(--color-border-secondary);
    }

    .border-top {
        border-top: solid 1px var(--color-border-secondary);
    }

    .border-left {
        border-left: solid 1px var(--color-border-secondary);
    }

    .border-right {
        border-right: solid 1px var(--color-border-secondary);
    }

    :not(:hover) > .reveal-on-parent-hover:not(:focus-within) {
        opacity: 0;
    }

    :not(:hover) > .show-on-parent-hover,
    :hover > .hide-on-parent-hover {
        display: none;
    }

    .list-item {
        transition: box-shadow 0.2s;
    }

    .list-item:not(:first-child) {
        border-top-style: solid;
        border-top-width: 1px;
        border-top-color: var(--list-item-border-color, var(--color-border-secondary));
    }

    .list-item.box:not(:first-child) {
        margin-top: var(--spacing);
    }

    .list-item[aria-selected="true"] {
        background: var(--list-item-selected-background);
        color: var(--list-item-selected-color);
        --color-highlight: var(--list-item-selected-color-highlight);
        box-shadow: inset 0.2em 0 0 0 var(--color-highlight);
    }

    .list-item:focus:not([aria-selected="true"]) {
        color: var(--list-item-focus-color);
        box-shadow: inset 0.2em 0 0 0 var(--color-highlight);
    }

    .section-header {
        font-variant: all-small-caps;
        letter-spacing: 0.1em;
        font-weight: 600;
    }

    .menu-item {
        display: flex;
        padding: var(--spacing-md, 8px) var(--spacing-lg, 12px);
        align-items: center;
        gap: var(--spacing-lg, 12px);
        flex: 1 0 0;
        border-radius: var(--radius-sm, 6px);
        background: var(--color-background-primary, #FFF);        
        color: var(--color-text-secondary-700, #414651);
        font-weight: var(--menu-item-weight);
        cursor: pointer;
    }

    .menu-item .stretch {
        width: 0;
    }

    .menu-item:hover {
        background: var(--color-background-active);
        color: var(--color-text-secondary-hover, #252B37);
    }

    .menu-item:active {
        background: var(--color-background-tertiary);
    }

    .menu-item[aria-selected="true"] {
        background: var(--color-background-active);
        color: var(--color-text-secondary-hover);
    }

    .menu-item[aria-selected="true"]:hover {
        background: var(--color-background-secondary-hover, #F5F5F5);
    }

    .menu-item[aria-selected="true"]:active {
        background: var(--color-background-quaternary);
    }    

    .menu-item .dropdown-icon {
        transition: transform 0.3s;
    }

    .menu-item[aria-expanded="false"] .dropdown-icon {
        transform: rotate(-90deg);
    }

    .hide-scrollbar {
        scrollbar-width: none;
        -ms-overflow-style: none;
        -webkit-overflow-scrolling: touch;
    }

    .hide-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .header-title {
        font-size: var(--header-title-size);
        font-weight: var(--header-title-weight);
    }

    .scrim {
        background: var(--color-background-primary);
        opacity: 0.8;
    }

    .table-with-truncated-cells {
        table-layout: fixed;
    }

    .percent-column-10 {
        width: 10%;
    }

    .percent-column-20 {
        width: 20%;
    }

    .truncate {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .list-item-box {
        display: flex;
        padding: var(--spacing-xxs) var(--spacing-md);
        align-items: center;
        gap: var(--spacing-md);
        align-self: stretch;
        cursor: pointer;
    }

    .list-item-content {
        display: flex;
        padding: var(--spacing-md) var(--spacing-md) var(--spacing-md) 10px;
        align-items: center;
        gap: var(--spacing-md);
        flex: 1 0 0;
        border-radius: var(--radius-md);
    }

    .list-item-content:hover {
        background: var(--color-background-primary-hover);
    } 
        
    .list-item-content:focus-visible {
        box-shadow: 0px 0px 0px 2px var(--color-effect-focus-ring);
    }

    .list-item-text {
        color: var(--color-text-primary-900);
        font-size: var(--font-size-sm);
        font-style: normal;
        font-weight: 500;
        line-height: var(--line-height-text-sm);
    }

    .button-ripple {
        position: absolute;
        border-radius: 50%;
        transform: scale(0);
        --ripple-duration: 0.6s;
        animation: circle-ripple-motion var(--ripple-duration) linear;
        pointer-events: none;
        background: currentColor;
        opacity: 0.4;
        z-index: 10;
    }

    @keyframes circle-ripple-motion {
        from {
            transform: scale(0);
            opacity: 0.35;
        }
        to {
            transform: scale(4);
            opacity: 0;
        }
    }  
        
    .showing-popover {
        transform: scaleX(0.97) scaleY(0.97);
        opacity: 0.7;
        -webkit-font-smoothing: auto;
        -moz-osx-font-smoothing: auto;
    }    
`;
